<template>
  <div class="home zt">
    <div>
      <div class="bigbox d-flex">
        <img
          src="@/assets/images/bigkill/getBnt.png"
          @click="$router.push({name:'bigKillhome'})"
          alt
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">
.home {
  width: 80%;
  margin: 37px auto;
  div {
    .bigbox {
      width: 500px;
      height: 487px;
      margin: auto;
      align-items: center;
      justify-content: center;
      background: url("~@/assets/images/bigkill/bigkillBg.png") no-repeat;
      background-size: 100% 100%;
      img {
        width: 169px;
        height: 50px;
        margin-top: 50px;
      }
    }
  }
}
</style>